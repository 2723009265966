// Подключение сторонних плагинов
import SmoothScroll from "smooth-scroll";
// import Swiper from "swiper";
// import tingle from "tingle.js";
import AOS from "aos";

AOS.init({
  disabled: "mobile",
});

var scroll = new SmoothScroll('a[href="#contacts"]');

/**   -----  Слайдер в товаре  ------
let swiper = new Swiper(".swiper-thumb", {
  loop: true,
  spaceBetween: 10,
  slidesPerView: 2,
  freeMode: true,
  watchSlidesProgress: true,
  slideToClickedSlide: true,
});

const items = document.querySelectorAll(".item");
items.forEach(function (item) {
  const thumbs = item.querySelectorAll(".swiper-thumb img");
  thumbs.forEach(function (thumb) {
    thumb.addEventListener("click", () => {
      const mainImg = item.querySelector(".main_img");
      mainImg.setAttribute("src", thumb.getAttribute("src"));
    });
  });
});

  -----  Модалка для картинки  ------
const modal = new tingle.modal({
  closeMethods: ["overlay", "button", "escape"],
  closeLabel: "Close",
  cssClass: ["modal-image"],
});


document.querySelectorAll(".main_img").forEach((image) => {
  image.addEventListener("click", () => {
    modal.setContent(`<img src="${image.src}" alt="${image.alt}">`);
    modal.open();
  });
});
*/
